import $ from 'jquery';

$(".search-widget").on("click", "a#search", function(e){
    e.preventDefault();
    $(".search-input-wrapper").addClass("active");
    $(".search-input-wrapper").find("input").focus();
});

$(".search-widget").on("click", "a#close", function(e){
    e.preventDefault();
    $(".search-input-wrapper").removeClass("active");
});